import React, { useMemo } from "react";
import { useTranslation } from "../../../fe-ui/hooks/use-translation";
import { alpha, Box, Container, keyframes, Typography, useMediaQuery, useTheme } from "@mui/material";
import FormSearch from "../../components/FormSearch/FormSearch";
import { ExtendedHotelDto } from "../../../api/aggregation-client/service";
import { HeaderBannerListResponseDataItem } from "../../../api/content-client/service";
import bannerFallback from "../../../ui/layouts/main/header.webp";

export const MainPageSearchWithBanners = ({
  hotels,
  banners,
}: {
  hotels?: ExtendedHotelDto[];
  banners?: HeaderBannerListResponseDataItem[];
}) => {
  const t = useTranslation();
  const theme = useTheme();

  const isXS = useMediaQuery(theme.breakpoints.down("sm"));
  const isSM = useMediaQuery(theme.breakpoints.down("md"));
  const computedMB = isXS ? 4 : 4;
  const computedMT = isSM ? 8 : 12;

  const images = useMemo(() => {
    const images = banners
      ?.map((b) => {
        const image = isXS ? b.attributes?.imageMobile : b.attributes?.image;
        if (!image) {
          return null;
        }
        return image.data?.attributes?.url;
      })
      .filter(Boolean);

    if (!images || images.length === 0) {
      return [bannerFallback.src];
    }

    return images;
  }, [isXS]);

  const backgroundChangeAnimation = useMemo(() => {
    return keyframes`
      ${images.map((img, i) => `${(i / images.length) * 100}% { background-image: url(${img}); }`)}
      100% { background-image: url(${images[0]}); }
    `;
  }, [images]);

  return (
    <Container
      id={"header-search-form"}
      maxWidth={"lg"}
      sx={{ mt: computedMT, mb: computedMB, position: "relative", minHeight: isXS ? 500 : 340 }}
    >
      <Box
        sx={{
          animation: `${backgroundChangeAnimation} ${images.length * 10}s ease infinite`,
          position: "absolute",
          top: 0,
          left: -16,
          right: -16,
          bottom: 0,
          backgroundColor: "#002D62",
          borderRadius: 2,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundImage: `url(${bannerFallback.src})`,
          zIndex: -1,
        }}
      />

      <Box sx={{ position: "absolute", bottom: 0, left: 0, right: 0, transform: `translateY(${20}px)` }}>
        <Container maxWidth={"lg"}>
          <Typography
            sx={{
              color: "#FFFFFF",
              textAlign: "center",
              fontWeight: 600,
              fontSize: isXS ? "24px" : "34px",
              lineHeight: isXS ? "28px" : "36px",
              textShadow: `0px 4px 4px ${alpha("#000000", 0.53)}`,
              mb: isXS ? 4 : 6,
            }}
          >
            {t("Home_header_search_title")}
          </Typography>
          <FormSearch navigateToSearchPage hotels={hotels} hasShadow />
        </Container>
      </Box>
    </Container>
  );
};
